import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: '需要了解的内容',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: '如果会下雨，就会发生洪水。',
      body:
        '即使处于远离海滨的地区，您也可能需要购买额外的洪水保险。'
    },
    smallBusiness: {
      image: umbrella,
      header:
        '企业房产保险可能不包括因洪水对库存或设备造成的损失。',
      body:
        '明智地应对洪水，并与经纪人谈论<a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">国家洪水保险计划</a>，保护您的企业免受洪水侵袭。'
    },
    understandOptions: {
      image: multifamily,
      header:
        '建筑业主需要了解他们的洪水保险方案。',
      body:
        '许多建筑业主可能没有意识到他们的房产需要的洪水保险，确保在发生洪水时为结构和物品提供保护。'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: '洪水保费大幅增加。',
      body:
        '2012年，国会取消了对老年住房的补贴；与此同时，由于气候变化，洪水风险也在增加。也就是说，更多的纽约人将面临更高的保费，尤其是地图更新后。'
    },
    legacyRate: {
      image: glasses,
      header: '如果现在购买，保费可能会更低。',
      body:
        '在新地图生效之前购买洪水保险可能意味着从长远来看保费要便宜得多。'
    },
    currentRate: {
      image: inspection,
      header: '检查您的当前保费至关重要。',
      body:
        '尽管联邦应急管理局为所有保险公司设定了统一的费率，但每个家庭都是独一无二的，所以您可能支付了过多的保费。'
    },
    rain: {
      image: chanceOfStorm,
      header: '如果会下雨，就会发生洪水。',
      body:
        '即使处于远离海滨的地区，您也可能需要购买额外的洪水保险。'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        '租户可以只为公寓内的物品购买洪水保险。',
      body:
        '您对洪水中的建筑不负责任，但仍想保护您的物品。高风险地区以外的租户通常有资格享受优先风险政策。'
    },
    understandOptions: {
      image: multifamily,
      header:
        '建筑业主需要了解他们的洪水保险方案。',
      body:
        '许多建筑业主可能没有意识到他们的房产需要的洪水保险，确保在发生洪水时为结构和物品提供保护。'
    }
  }
};
