export default {
  homepage: 'FloodHelpNY',
  languageTitle: 'Languages',
  about: 'About Us',
  languages: {
    english: 'English',
    spanish: 'Español',
    russian: 'Русский',
    chinese: '中文',
    haitian: 'Kreyòl ayisyen'
  },
  resources: {
    insurance: 'Insurance',
    retrofits: 'Retrofits',
    hurricane: 'Hurricane Season',
    smallBusinesses: 'Small Business',
    news: 'News',
    stormwaterFlooding: 'Stormwater Flooding',
    climateChange: 'Climate Change'
  }
};
