import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: 'Lo que debe saber',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: 'Si llueve, puede inundarse',
      body:
        'Aún si usted está en un área lejos de la costa, podría querer comprar cobertura adicional de seguro contra inundación.'
    },
    smallBusiness: {
      image: umbrella,
      header:
        'Es posible que el seguro de propiedad comercial no cubra el costo de los daños causados por la inundación de su inventario o equipo.',
      body:
        'Sea inteligente en cuanto a inundaciones y hable con un corredor sobre el <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">Programa Nacional de Seguro contra Inundaciones (NFIP)</a> para proteger su negocio de las inundaciones.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Los propietarios de edificios deben entender sus opciones de seguro contra inundación.',
      body:
        'Muchos propietarios de edificios podrían no darse cuenta de cuánta cobertura de seguro contra inundación necesitan para su propiedad para garantizar que su estructura y el contenido de la misma estén protegidos en caso de inundación.'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: 'Las primas de seguro contra inundación están aumentando considerablemente.',
      body:
        'En 2012, el Congreso eliminó los subsidios para hogares más viejos, al mismo tiempo, el riesgo de inundación también está aumentando debido al cambio del clima. Esto significa que más neoyorquinos se enfrentarán a primas más altas, especialmente cuando se actualicen los mapas.'
    },
    legacyRate: {
      image: glasses,
      header: 'Si usted compra ahora, podrá obtener una tarifa más baja en el futuro de conformidad con cláusulas de antigüedad.',
      body:
        'Comprar seguro contra inundación antes de que los nuevos mapas entren en vigor podrá significar primas mucho más bajas a largo plazo.'
    },
    currentRate: {
      image: inspection,
      header: 'Es fundamental verificar su tarifa actual.',
      body:
        'Aunque FEMA fija las mismas tarifas para todas las compañías de seguro, cada vivienda es única, de manera que es posible que esté pagando demasiado.'
    },
    rain: {
      image: chanceOfStorm,
      header: 'Si llueve, puede inundarse',
      body:
        'Aún si usted está en un área lejos de la costa, podría querer comprar cobertura adicional de seguro contra inundación.'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        'Los inquilinos pueden comprar seguro contra inundación solo para el contenido de su apartamento.',
      body:
        'Usted no es responsable por el edificio en una inundación, pero aun así quiere proteger sus pertenencias. Y los inquilinos fuera de las zonas de riesgo alto generalmente califican para una Póliza de Riesgo Preferente.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Los propietarios de edificios deben entender sus opciones de seguro contra inundación.',
      body:
        'Muchos propietarios de edificios podrían no darse cuenta de cuánta cobertura de seguro contra inundación necesitan para su propiedad para garantizar que su estructura y el contenido de la misma estén protegidos en caso de inundación.'
    }
  }
};
