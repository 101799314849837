import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: 'What to Know',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: 'If it can rain, it can flood.',
      body:
        'Even if you’re in an area far from the waterfront, you may want to purchase additional flood insurance coverage.'
    },
    smallBusiness: {
      image: umbrella,
      header:
        'Business property insurance may not cover the cost of damage caused by flooding to your inventory or equipment.',
      body:
        'Be flood smart and talk to a broker about the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">National Flood Insurance Program (NFIP)</a> to protect your business from flooding.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Building owners need to understand their flood insurance options.',
      body:
        'Many building owners may not realize how much flood insurance coverage they need for their property to ensure their structure and contents are protected in the event of a flood.'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: 'Flood insurance premiums are increasing significantly.',
      body:
        'In 2012, Congress eliminated subsidies on older homes; at the same time, flood risk is also increasing because of climate change. This means more New Yorkers will face higher premiums, especially when the maps are updated.'
    },
    legacyRate: {
      image: glasses,
      header: 'If you buy now, save later. You may get a lower legacy rate.',
      body:
        'Buying flood insurance before new maps take effect may mean much cheaper premiums over the long term.'
    },
    currentRate: {
      image: inspection,
      header: 'Checking your current rate is critical.',
      body:
        'Even though FEMA sets the same rates for all insurance companies, each home is unique so it’s possible you’re paying too much.'
    },
    rain: {
      image: chanceOfStorm,
      header: 'If it can rain, it can flood.',
      body:
        'Even if you’re in an area far from the waterfront, you may want to purchase additional flood insurance coverage.'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        'Renters can purchase flood insurance for just the contents of their apartment.',
      body:
        'You’re not liable for the building in a flood, but you still want to protect your stuff. And renters outside of high-risk zones usually qualify for a Preferred Risk Policy.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Building owners need to understand their flood insurance options.',
      body:
        'Many building owners may not realize how much flood insurance coverage they need for their property to ensure their structure and contents are protected in the event of a flood.'
    }
  }
};
