import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: 'Sa Pou Ou Konnen',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: 'Si l ka fè lapli, li ka fè inondasyon.',
      body:
        'Menm si ou nan yon espas ki lwen bò dlo an, ou gendwa vle achte kouvèti asirans kont inondasyon anplis.'
    },
    smallBusiness: {
      image: umbrella,
      header:
        'Asirans pou pwopriyete komèsyal gendwa pa kouvri depans domaj nan inondasyon lakoz yo nan stòk oswa ekipman ou yo.',
      body:
        'Fòk ou vin entelijan pou inondasyon epi pale ak yon koutye apwopo <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">Pwogram Nasyonal Asirans kont Inondasyon</a> an pou pwoteje biznis ou kont inondasyon.Be flood smart and talk to a broker about the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">National Flood Insurance Program (NFIP)</a> to protect your business from flooding.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Pwopriyetè batiman yo bezwen konprann opsyon asirans kont inondasyon yo.',
      body:
        'Anpil pwopriyetè batiman gendwa pa reyalize konbyen asirans kont inondasyon yo bezwen pou pwopriyete yo an pou asire estrikti yo epi kontni yo pwoteje nan ka yon inondasyon.'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: 'Prim asirans kont inondasyon yo ap ogmante nan yon nivo enpòtan.',
      body:
        'An 2012, Kongrè te elimine sibvansyon sou ansyen kay yo; an menm tan, risk pou inondasyon an ap ogmante tou akoz chanjman klimatik lan. Sa vle di plis abitan New York pral konfwonte pi gwo prim, espesyalman lè kat yo ajou.'
    },
    legacyRate: {
      image: glasses,
      header: 'Si ou achte kounya, ou gendwa benefisye yon dwa ou nan yon tarif ki pi ba.',
      body:
        'Achte asirans kont inondasyon anvan nouvo kat yo pran efè gendwa vle di prim ki pi ba pri sou yon peryòd ki pi long.'
    },
    currentRate: {
      image: inspection,
      header: 'Verifye tarif aktyèl ou an esansyèl.',
      body:
        'Menm si FEMA etabli menm tarif yo pou tout konpayi asirans, chak kay san parèy donk li posib ou ap peye twòp kòb.'
    },
    rain: {
      image: chanceOfStorm,
      header: 'Si l ka fè lapli, li ka fè inondasyon.',
      body:
        'Menm si ou nan yon espas ki lwen bò dlo an, ou gendwa vle achte kouvèti asirans kont inondasyon anplis.'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        'Lokatè yo ka achte asirans kont asirans pou senpman kontni apatman yo.',
      body:
        'Ou pa responsab batiman an nan yon inondasyon, men ou toujou vle pwoteje afè ou yo. Epi lokatè ki andeyò zòn gwo risk yo abityèlman kalifye pou yon Politik Risk Favori.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Pwopriyetè batiman yo bezwen konprann opsyon asirans kont inondasyon yo.',
      body:
        'Anpil pwopriyetè batiman gendwa pa reyalize konbyen asirans kont inondasyon yo bezwen pou pwopriyete yo an pou asire estrikti yo epi kontni yo pwoteje nan ka yon inondasyon.'
    }
  }
};
