import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: 'Что необходимо знать',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: 'Если может пойти дождь, может быть и наводнение.',
      body:
        'Даже если вы находитесь в зоне, удаленной от прибрежной части города, возможно, вам стоит приобрести дополнительное страхование от наводнения.'
    },
    smallBusiness: {
      image: umbrella,
      header:
        'Страхование собственности предприятия может не покрывать ущерб, нанесенный наводнением вашим товарам и оборудованию. ',
      body:
        'Будьте осведомленными при страховании от наводнения и поговорите с брокером о <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">Национальной программе страхования от наводнений</a>, чтобы защитить ваш бизнес от наводнения.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Владельцы зданий должны понимать их варианты страхования от наводнения.',
      body:
        'Многие владельцы зданий не понимают, какое покрытие страхования от наводнения необходимо для их собственности, чтобы гарантировать, что их здание и содержимое защищены в случае дождя.'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: 'Взносы за страхование от наводнения повышаются в значительной степени.',
      body:
        'В 2012г. Конгрессом были отменены субсидии на старые дома; в то же время риск наводнения также повышается из-за изменения климата. Это означает, что большее количество ньюйоркцев будет сталкиваться с более высокими взносами, особенно когда карты будут обновляться.'
    },
    legacyRate: {
      image: glasses,
      header: 'Если вы приобретаете страховку сейчас, вы можете унаследовать более низкий тариф.',
      body:
        'Приобретение страхования от наводнения до того, как новые карты вступят в силу может означать более дешевые взносы в перспективе.'
    },
    currentRate: {
      image: inspection,
      header: 'Проверка вашего текущего тарифа чрезвычайно важна.',
      body:
        'Несмотря на то, что FEMA устанавливает одинаковые расценки для всех страховых компаний, каждый дом уникален, так что существует возможность, что вы платите слишком много. '
    },
    rain: {
      image: chanceOfStorm,
      header: 'Если может пойти дождь, может быть и наводнение.',
      body:
        'Даже если вы находитесь в зоне, удаленной от прибрежной части города, возможно, вам стоит приобрести дополнительное страхование от наводнения.'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        'Арендаторы могут приобрести страхование от наводнения только для содержимого их квартиры.',
      body:
        'Вы не отвечаете за здание во время наводнения, но вы все же хотите сохранить ваше имущество. Арендаторы вне зоны повышенного риска обычно соответствуют критериям на получение Полиса предпочтительного риска.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Владельцы зданий должны понимать их варианты страхования от наводнения.',
      body:
        'Многие владельцы зданий не понимают, какое покрытие страхования от наводнения необходимо для их собственности, чтобы гарантировать, что их здание и содержимое защищены в случае дождя.'
    }
  }
};
